<template>
    <b-modal centered hide-footer
             v-bind:title="$t('message.account.change-password')"
             v-model="isModalOpen"
             v-on:cancel="hideModal">
        <div class="form-row">
            <b-form-group class="col-md-12"
                          label-for="oldPassword"
                          v-bind:label="$t('message.account.current-password')"
                          v-bind:invalid-feedback="invalidPasswordFeedback"
                          v-bind:valid-feedback="validFeedback">
                <b-form-input id="oldPassword"
                              class="rounded-0"
                              v-bind:class="{ 'animate-shake': userStatus === 'loginFailed' }"
                              type="password"
                              v-model.trim="password"
                              required>
                </b-form-input>
            </b-form-group>
        </div>

        <div class="form-row">
            <b-form-group class="col-md-12"
                          label-for="newPassword"
                          v-bind:invalid-feedback="invalidFeedback"
                          v-bind:label="$t('message.account.new-password')"
                          v-bind:valid-feedback="validFeedback">
                <button type="button"
                        class="btn btn-link position-absolute reveal-password"
                        v-on:click="showPassword = !showPassword">
                    <span v-if="!showPassword">
                        <font-awesome-icon v-bind:icon="['far', 'eye']"></font-awesome-icon> {{ $t("message.registration.show-password") }}
                    </span>
                    <span v-else>
                        <font-awesome-icon v-bind:icon="['far', 'eye-slash']"></font-awesome-icon> {{ $t("message.registration.hide-password") }}
                    </span>
                </button>
                <b-form-input id="newPassword"
                              class="rounded-0"
                              v-bind:type="showPassword ? 'text' : 'password'"
                              v-model.trim="newPassword">
                </b-form-input>
            </b-form-group>
        </div>
        <div class="text-center">
            <button class="btn btn-dark verify-otp"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-bind:disabled="!(password && newPassword)"
                    v-on:click="changePassword">
                {{ $t("message.account.change-password") }}
            </button>
        </div>
    </b-modal>
</template>

<script>
import {
    BFormInput,
    BFormGroup,
    BModal,
} from "bootstrap-vue";

import changePassword from "../../api/user/changePassword";

export default {
    name: "ModalChangePassword",
    components: {
        BFormInput,
        BFormGroup,
        BModal,
    },
    props: {
        "value": {
            type: Boolean,
        },
    },
    data () {
        return {
            isLoading: false,
            showPassword: false,

            newPassword: "",
            password: "",
        };
    },
    computed: {
        isModalOpen: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },

        userStatus () {
            return this.$store.state.user.status;
        },

        invalidPasswordFeedback () {
            if (this.userStatus === "loginFailed") {
                return this.$t("message.login.incorrect-login");
            } else {
                if (this.password) {
                    return "";
                } else {
                    return this.$t("message.login.invalid-password");
                }
            }
        },

        validFeedback () {
            return "";
        },
    },
    methods: {
        reset () {
            this.showPassword = false;
            this.newPassword = "";
            this.password = "";
        },

        hideModal () {
            this.reset();
            this.isModalOpen = false;
        },

        async changePassword () {
            this.isLoading = true;
            try {
                await changePassword(this.password, this.newPassword);
            } catch (e) {
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.reset();
            this.isModalOpen = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.reveal-password {
    top: 0;
    right: 0;

    &:hover {
        text-decoration: none;
    }
}

.animate-shake {
    animation-iteration-count: 1;
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-name: shake;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}
</style>
