<template>
    <b-modal centered hide-footer
             v-bind:title="$t('message.account.disable-two-factor-auth')"
             v-model="isModalOpen"
             v-on:cancel="closeModal">
        <p>Are you sure you want to disable one-time password? </p>
        <div class="text-center">
            <button class="btn btn-dark"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click="disableOTP">
                {{ $t("message.account.disable") }}
            </button>
        </div>
    </b-modal>
</template>

<script>
import disable2FA from "../../api/user/disable2FA";

import {
    BModal,
} from "bootstrap-vue";

export default {
    name: "ModalDisableOTP",
    components: {
        BModal,
    },
    props: {
        "value": {
            type: Boolean,
        },
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        isModalOpen: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        closeModal () {
            this.isModalOpen = false;
        },

        async disableOTP () {
            this.isLoading = true;
            try {
                await disable2FA("otp");
            } catch (e) {
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.isModalOpen = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
