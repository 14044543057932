<template>
    <div>
        <b-modal centered hide-footer
                 v-bind:title="$t('message.account.enable-two-factor-auth')"
                 v-model="isModalOpen"
                 v-on:hidden="closeModal"
                 v-on:close="closeModal"
                 v-on:cancel="closeModal">
            <div>
                <h6 class="text-uppercase mb-0">
                    {{ $t("message.account.one-time-password") }}
                </h6>

                <p class="font-weight-light mb-1">
                    {{ $t("message.account.otp-description") }}
                </p>

                <button class="btn btn-link p-0 mb-3"
                        v-if="!otpSecret"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="enableOTP">
                    <span>{{ $t("message.account.enable") }}</span>
                </button>

                <b-collapse v-bind:visible="!!otpSecret">
                    <qr-code class="qr-code mx-auto mt-4 mb-3"
                             size="500"
                             v-bind:value="otpUri">
                    </qr-code>

                    <p class="text-center mb-3">
                        {{ $t("message.account.scan-enter-otp") }}
                    </p>

                    <h5 class="text-center mb-4">{{ otpSecret }}</h5>

                    <div class="text-center">
                        <button class="btn btn-dark"
                                v-on:click="verifyOTP">
                            {{ $t("message.account.verify-token") }}
                        </button>
                    </div>
                </b-collapse>
            </div>

            <!-- <div>
                <h6 class="text-uppercase mb-0">
                    {{ $t("message.account.hw-security-key") }}
                </h6>
                <p class="font-weight-light mb-1">
                    {{ $t("message.account.hw-security-key-description") }}
                </p>
                <button class="btn btn-link p-0 mb-3"
                        v-on:click="enableU2F">
                    <span>{{ $t("message.account.enable") }}</span>
                </button>
            </div> -->
        </b-modal>
        <modal-verify-o-t-p v-model="showCheck2FATokenModal"></modal-verify-o-t-p>
    </div>
</template>

<script>
import {
    BCollapse,
    BModal,
} from "bootstrap-vue";

import QrCode from "../QrCode";
import ModalVerifyOTP from "./ModalVerifyOTP";

import enable2FA from "../../api/user/enable2FA";

export default {
    name: "ModalActivateOTP",
    components: {
        ModalVerifyOTP,
        QrCode,
        BCollapse,
        BModal,
    },
    props: {
        "value": {
            type: Boolean,
        },
    },
    data () {
        return {
            isLoading: false,
            showCheck2FATokenModal: false,

            otpUri: null,
            otpSecret: null,
            otpToken: "",
        };
    },
    computed: {
        isModalOpen: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        closeModal () {
            this.isModalOpen = false;
        },

        async enableOTP () {
            this.isLoading = true;

            let result;
            try {
                result = await enable2FA(
                    "otp"
                );
            } catch (e) {
                this.isLoading = false;
                return;
            }

            this.isLoading = false;

            this.otpUri = result.uri;
            this.otpSecret = result.secret;
        },

        verifyOTP () {
            this.showCheck2FATokenModal = true;
            this.isModalOpen = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.qr-code {
    max-width: 300px;
}
</style>
