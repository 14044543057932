import { apiUrl, apiVersion, } from "../../config";

import localforage from "localforage";

export async function enable2FA (type) {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/2fa/${ type }`,
        {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        const result = await response.json();
        if (result) {
            return result;
        }
        throw "USER Enable 2FA error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default enable2FA;
