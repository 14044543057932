<template>
    <div class="account-info container pt-2 pb-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-3 mb-3 mb-lg-0">
                    <h4 class="text-left font-weight-bold">
                        {{ $t("message.account.account") }}
                    </h4>
                </div>
                <div class="col-lg-6">
                    <b-collapse v-bind:visible="!accordions.account">
                        <div class="row no-gutters"
                             v-show="!accordions.account">
                            <div class="col-lg-6">
                                <div>
                                    <h6 class="text-uppercase mb-0">
                                        {{ $t("message.account.name") }}
                                    </h6>
                                    <p class="font-weight-bold">
                                        {{ $i18n.locale === "en" ? `${ user.name } ${ user.surname }` : `${ user.surname } ${ user.name }` }}
                                    </p>
                                </div>
                                <div>
                                    <h6 class="text-uppercase mb-0">
                                        <span class="mr-1">
                                            {{ $t("message.account.phone") }}
                                        </span>
                                        <font-awesome-icon icon="shield-check"
                                                           style="color: #218838;"
                                                           v-if="user.phoneVerified">
                                        </font-awesome-icon>
                                    </h6>
                                    <p v-if="user.phone">
                                        <span class="font-weight-bold mr-1">
                                            {{ user.phone }}
                                        </span>
                                        <!-- <button class="btn btn-sm btn-link"
                                                v-if="!user.phoneVerified"
                                                v-on:click="verifyPhone">
                                            {{ $t("message.account.verify") }}
                                        </button> -->
                                    </p>
                                    <p v-else>
                                        {{ $t("message.account.no-data") }}
                                    </p>
                                </div>
                                <div>
                                    <h6 class="text-uppercase mb-0">
                                        {{ $t("message.account.language") }}
                                    </h6>
                                    <p class="font-weight-bold"
                                       v-if="user.profile">
                                        {{ langMap[user.profile.lang] }}
                                    </p>
                                    <p v-else>
                                        {{ $t("message.account.no-data") }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div>
                                    <h6 class="text-uppercase mb-0">
                                        <span class="mr-1">
                                            {{ $t("message.account.email") }}
                                        </span>
                                        <font-awesome-icon icon="shield-check"
                                                           style="color: #218838;"
                                                           v-if="user.verified">
                                        </font-awesome-icon>
                                    </h6>
                                    <p class="font-weight-bold">
                                        {{ user.email }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <b-collapse v-bind:visible="accordions.account">
                        <div class="row no-gutters">
                            <div class="col-lg-12">

                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                                <b-form-group label-for="email"
                                              v-bind:label="$t('message.account.email')"
                                              v-bind:invalid-feedback="invalidEmailFeedback"
                                              v-bind:valid-feedback="validFeedback">
                                    <b-form-input id="email"
                                                  class="rounded-0"
                                                  type="email"
                                                  v-model.trim="email"
                                                  required>
                                    </b-form-input>
                                    <b-form-text>
                                        {{ $t("message.account.change-email-notice") }}
                                    </b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                                <b-form-group label-for="phone"
                                              v-bind:label="$t('message.account.phone')"
                                              v-bind:invalid-feedback="invalidPhoneFeedback"
                                              v-bind:valid-feedback="validFeedback">
                                    <b-form-input id="phone"
                                                  class="rounded-0"
                                                  type="tel"
                                                  minlength="8"
                                                  maxlength="8"
                                                  v-model.trim="phone"
                                                  required>
                                    </b-form-input>
                                    <b-form-text>
                                        {{ $t("message.account.change-phone-notice") }}
                                    </b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div class="col-lg-3 text-lg-center">
                    <button class="btn btn-outline-dark"
                            v-show="!accordions.account"
                            v-on:click="accordions.account = true">
                        {{ $t("message.account.edit") }}
                    </button>
                    <button class="btn btn-outline-dark"
                            v-show="accordions.account"
                            v-on:click="updateAccount">
                        {{ $t("message.account.done") }}
                    </button>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-3 mb-3 mb-lg-0">
                    <h4 class="text-left font-weight-bold">
                        {{ $t("message.account.security") }}
                    </h4>
                </div>
                <div class="col-lg-9">
                    <div class="row no-gutters"
                         v-show="!accordions.security">
                        <div class="col-lg-4">
                            <div>
                                <h6 class="text-uppercase mb-0">
                                    {{ $t("message.account.password") }}
                                </h6>
                                <button class="btn btn-link p-0 mb-3"
                                        v-on:click="showChangePasswordModal = true">
                                    {{ $t("message.account.change-password") }}
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div>
                                <h6 class="text-uppercase mb-0">
                                    {{ $t("message.account.twoFactorAuth") }}
                                </h6>
                                <button class="btn btn-link p-0 mb-3"
                                        v-if="!user.twoFactorAuth"
                                        v-on:click="showEnable2FAModal = true">
                                    {{ $t("message.account.enable-two-factor-auth") }}
                                </button>
                                <p class="p-0 mb-3"
                                   v-else>
                                    {{ $t("message.account.enabled") }}
                                </p>

                                <div v-if="user.twoFactorAuth">
                                    <h6 class="text-uppercase mb-0">
                                        {{ $t("message.account.one-time-password") }}
                                    </h6>
                                    <button class="btn btn-link p-0 mb-3"
                                            v-if="user.twoFactorAuthTypes.includes('otp')"
                                            v-on:click="showDisable2FAModal = true">
                                        {{ $t("message.account.disable-otp") }}
                                    </button>

                                    <!-- <h6 class="text-uppercase mb-0">
                                        {{ $t("message.account.hw-security-key") }}
                                    </h6>
                                    <p>
                                        <span v-if="user.twoFactorAuthTypes.includes('u2f')">
                                            {{ $t("message.account.enable") }}
                                        </span>
                                        <span v-else>
                                            {{ $t("message.account.disable") }}
                                        </span>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-3 mb-3 mb-lg-0">
                    <h4 class="text-left font-weight-bold">
                        {{ $t("message.account.payment-shipping") }}
                    </h4>
                </div>
                <div class="col-lg-6">
                    <b-collapse v-bind:visible="!accordions.paymentShipping">
                        <div class="row no-gutters">
                            <div class="col-lg-6">

                            </div>
                            <div class="col-lg-6">

                            </div>
                        </div>
                    </b-collapse>
                    <b-collapse v-bind:visible="accordions.paymentShipping">
                        <div class="row no-gutters">
                            <div class="col-lg-6">

                            </div>
                            <div class="col-lg-6">

                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div class="col-lg-3 text-lg-center">
                    <button class="btn btn-outline-dark"
                            v-on:click="accordions.paymentShipping = !accordions.paymentShipping">
                        {{ $t("message.account.edit") }}
                    </button>
                </div>
            </div>
            <!-- <hr>
            <div class="row">
                <div class="col-lg-3 mb-3 mb-lg-0">
                    <h4 class="text-left font-weight-bold">
                        {{ $t("message.account.message-options") }}
                    </h4>
                </div>
                <div class="col-lg-6">
                    <div>
                        <h6 class="text-uppercase mb-0">Subscription</h6>
                        <p>Please select the means and topics you would like to receive from marier. </p>
                        <h6 class="text-uppercase mb-0">Means</h6>
                        <p></p>
                        <h6 class="text-uppercase mb-0">Topics</h6>
                        <p></p>
                    </div>
                    <b-collapse v-model="accordions.messageOptions">

                    </b-collapse>
                </div>
                <div class="col-lg-3 text-lg-center">
                    <button class="btn btn-outline-dark"
                            v-on:click="accordions.messageOptions = !accordions.messageOptions">
                        {{ $t("message.account.edit") }}
                    </button>
                </div>
            </div> -->
        </div>

        <modal-change-password v-model="showChangePasswordModal"></modal-change-password>
        <modal-activate-o-t-p v-model="showEnable2FAModal"></modal-activate-o-t-p>
        <modal-disable-o-t-p v-model="showDisable2FAModal"></modal-disable-o-t-p>
    </div>
</template>

<script>
import isEmail from "validator/lib/isEmail";

import enable2FA from "../../api/user/enable2FA";
import verify2FA from "../../api/user/verify2FA";
import disable2FA from "../../api/user/disable2FA";

import {
    BFormInput,
    BFormGroup,
    BCollapse,
} from "bootstrap-vue";

import ModalActivateOTP from "../modals/ModalActivateOTP";
import ModalDisableOTP from "../modals/ModalDisableOTP";
import ModalChangePassword from "../modals/ModalChangePassword";

export default {
    name: "AccountInfo",
    components: {
        ModalChangePassword,
        ModalDisableOTP,
        ModalActivateOTP,
        BFormInput,
        BFormGroup,
        BCollapse,
    },
    metaInfo () {
        return {
            title: this.$t("message.account.dashboard"),
            meta: [{
                "name": "description",
                "content": this.$t("message.account.dashboard"),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$t("message.account.dashboard"),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$t("message.account.dashboard"),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "website",
                "vmid": "og:type"
            }]
        }
    },
    data () {
        return {
            langMap: {
                "en": "English",
                "zh-hk": "繁體中文",
                "zh-cn": "简体中文"
            },

            accordions: {
                account: false,
                security: false,
                paymentShipping: false,
                messageOptions: false,
            },

            showChangePasswordModal: false,
            showEnable2FAModal: false,
            showDisable2FAModal: false,
            showCheck2FATokenModal: false,

            email: "",
            phone: "",
            profile: {
                lang: ""
            }
        }
    },
    computed: {
        user () {
            return this.$store.state.user.user;
        },

        invalidUsernameFeedback () {
            if (this.userStatus === "loginFailed") {
                return this.$t("message.login.incorrect-login");
            } else {
                if (this.mode === "email") {
                    if (isEmail(this.username)) {
                        return "";
                    } else {
                        return this.$t("message.login.invalid-email");
                    }
                }

                if (this.mode === "phone") {
                    if (this.regionCode && this.username.length === 8) {
                        return "";
                    } else {
                        return this.$t("message.login.invalid-phone");
                    }
                }
            }
        },

        invalidEmailFeedback () {
            if (!this.email) {
                return "請輸入你的電郵地址";
            }

            if (!isEmail(this.email)) {
                return "電子郵件地址無效";
            }

            return "";
        },

        invalidPhoneFeedback () {
            return "";
        },

        validFeedback () {
            return "";
        },

        validEmailFeedback () {
            return this.$t("message.account.change-email-notice");
        },

        validPhoneFeedback () {
            return this.$t("message.account.change-phone-notice");
        },
    },
    methods: {
        async updateAccount () {
            this.accordions.account = false;
        },

        async verifyPhone () {

        },

        async enableU2F () {
            let result;
            try {
                result = await enable2FA("u2f");
            } catch (e) {

            }
            console.log(result);
        },

        async verifyU2F () {
            let result;
            try {
                result = await verify2FA("u2f");
            } catch (e) {

            }
            console.log(result);
        },

        async disableU2F () {
            let result;
            try {
                result = await disable2FA("u2f");
            } catch (e) {

            }
            console.log(result);
        },
    },
    mounted () {
        if (this.user) {
            this.email = this.user.email;
            this.phone = this.user.phone;
            this.profile = this.user.profile;
        }
    },
    watch: {
        "user" (newVal) {
            if (newVal) {
                this.email = newVal.email;
                this.phone = newVal.phone;
                this.profile = newVal.profile;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.account-info {

}
</style>
