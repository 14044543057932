import { apiUrl, apiVersion, } from "../../config";

import localforage from "localforage";

function hexString(buffer) {
    const byteArray = new Uint8Array(buffer);
    const hexCodes = [...byteArray].map(
        value => {
            const hexCode = value.toString(16);
            return hexCode.padStart(2, "0");
        }
    );
    return hexCodes.join("");
}

function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-512", data);
}

export async function changePassword (
    password,
    newPassword
) {
    const hashedOld = await digestMessage(password);
    const hashedNew = await digestMessage(newPassword);
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/password`,
        {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(
                {
                    "password": hexString(hashedOld),
                    "newPassword": hexString(hashedNew),
                }
            ),
        }
    );

    if (response.ok) {
        return;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default changePassword;
